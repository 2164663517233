import React from "react";
import Layout from "../../components/layout/Layout";
import SEO from "../../components/SEO";
import Header from "../../components/layout/Header.js";
import SiteTitleQuery from "../../components/SiteTitleQuery";
import InspectionImage from "../../images/inHouseImages/inspectionImage.jpg";
import ProfileCard from "../../components/coworkers/Profile.js";
import Ann from "../../images/profiles/profileAnn.jpg";

function Authorities() {
  return (
    <SiteTitleQuery
      render={data => (
        <Layout title={data.site.siteMetadata.title}>
          <SEO
            title="Myndighetsärenden"
            keywords={[
              `myndighet`,
              `myndighetsärenden`,
              `lagkrav`,
              `lagstöd`,
              `tillsyn`,
              `tillstånd`
            ]}
          />
          <Header data={data} />

          <div className="container mx-auto flex flex-col md:flex-row items-center my-8 md:my-10 justify-center">
            <div className="sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-3xl rounded overflow-hidden shadow">
              <img
                className="w-full"
                src={InspectionImage}
                alt="myndighetsärenden"
                title="myndighetsärenden"
              ></img>
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">Myndighetsärenden</div>
                <p className="text-gray-700 text-base">
                  PSK är din samarbetspartner med en komplett lösning när det
                  handlar om myndighetskontakt, oavsett om det behövs hjälp
                  efter en tillsyn, en ombyggnation, ansökan av
                  tillståndshandlingar, CAD-ritningar av olika slag och mycket
                  mer därtill.
                  <br></br>
                  <br></br>
                  Vi tillhandahåller en tjänst som skapar en smidigare
                  myndighetskontakt där all data kring myndighetsrelaterade
                  ärenden lagras digitalt. Med oss får ni noggranna analyser av
                  rapporter, skrivelser och beslut görs utifrån samråd med er.
                  <br></br>
                  Dessutom utförs en bedömning av det som myndigheten kräver och
                  om ett korrekt lagstöd i detta finns. Utöver detta
                  tillhandahåller vi stöd kring kompletterande handlingar och
                  åtgärder.
                  <br></br>
                  <br></br>
                  PSK följer hela processen från början till slut. Utifrån
                  handlingsunderlaget bedömer vi om det finns behov att delegera
                  ut arbetsorder till entreprenörer på olika åtgärder, därefter
                  sammanställer vi entreprenörernas rapporter och slutligen
                  återkopplar till myndigheten.
                  <br></br>
                  <br></br>
                  Med oss kan ni vara trygga med att utsatt deadline ej
                  passeras.
                  <br></br> 
                  Detta säkerställs genom den hanterings- och
                  påminnelsefunktion som vi utvecklat själva.
                  <br></br>
                  Under ärendets gång håller vi en nära dialog med er som kund,
                  och vi är alltid tillgängliga för en optimal hantering.
                  <br></br>
                  <br></br>
                  Vi har gedigen kunskap inom olika områden samt ett stort
                  engagemang som vi tillämpar för att ni ska få en effektiv
                  process kring er myndighetshantering.
                </p>
              </div>

              <ProfileCard
                profile={Ann}
                name="Ann Johansson"
                email="aj@psksyd.com"
              />
            </div>
          </div>
        </Layout>
      )}
    />
  );
}

export default Authorities;
